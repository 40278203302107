.App {
  text-align: center;
  position: relative; /* Ensure the menu is properly positioned */
}

header {
  position: relative; /* To position the menu button correctly */
  padding: 0 40px; /* Add some padding for better alignment */
  display: flex;
  align-items: center;
  justify-content: space-between; /* Space out logo and menu toggle */
  background-color: #ffffff;
   /* Ensure the header has the same color as the nav */
}

.menu-toggle {
  display: none; /* Hidden by default */
  background-color: #fbfffc;
  color: #ff6600;
  border: none;
  cursor: pointer;
  position: fixed; /* Fixed positioning to stay at the top */
  top: 10px; /* Adjust as needed */
  right: 10px; /* Adjust as needed */
  z-index: 1000; /* Ensure it is above other elements */
}

nav {
  background-color: #ffffff;
  padding:  0;
  position: fixed; /* Fixed positioning to stay at the top */
  width: 100%; /* Full width to cover the top */
  top: 0; /* Stick to the top */
  left: 0;
  z-index: 999; /* Ensure it's below the menu toggle button */
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Align items vertically */
}

nav li {
  margin: 0 10px; /* Add margin between menu items */
}

nav a {
  display: block;
  font-size: 22px;
  font-weight: 700;
  color: #026817;
  text-align: center;
  padding: 14px 20px; /* Padding for top/bottom consistency */
  text-decoration: none;
}

nav a:hover {
  background-color: #b5c3b8; /* Hover color for links */
}
.logo-container {
  flex: 1;
}

@media (max-width: 768px) {
  .menu-toggle {
    display: block; /* Show menu button on mobile */
  }

  nav ul {
    display: none; /* Hide menu items by default */
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #ffffff;
    position: absolute; /* Position below the menu toggle button */
    top: 60px; /* Adjust as needed to fit below the menu button */
    left: 0;
    z-index: 999; /* Ensure it is below the menu button */
  }

  nav.show ul {
    display: flex; /* Show menu items when toggled */
  }

  nav a {
    padding: 10px; /* Reduce padding on mobile */
  }
}

.logoImageHead {
  max-width: 100px;
  height: auto; /* Ensure the logo scales properly */
  margin-right: 20px;
}

.menu-toggle {
  background: #026817;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  margin-bottom: 10px;
}

.nav.show {
  display: block; /* Ensure the nav shows when the menu is toggled */
}

.nav {
  display: none; /* Hide the nav by default on mobile */
}

@media (min-width: 769px) {
  .menu-toggle {
    display: none; /* Hide the toggle button on larger screens */
  }

  .nav {
    display: flex; /* Display the nav as flex on larger screens */
  }

  .navList {
    flex-Direction: 'row'; /* Ensure links are horizontal on larger screens */
  }
}

