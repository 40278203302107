.container {
    padding: 20px;
    text-align: center;
  }
  
  .header {
    font-size: 2.5rem;
    color: #026817;
    margin-bottom: 20px;
  }
  
  .grid {
    display: grid;
    grid-template-columns: repeat(3, 0fr);
    gap: 20px;
    justify-content: center;
    margin-top: 20px;
  }
  
  @media (max-width: 1200px) {
    .grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 767px) {
    .grid {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  
  .card {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 350px;
    height: 500px;
    text-align: center;
    box-sizing: border-box;
    transition: transform 0.3s ease;
    cursor: pointer;
    display: flex;
    flex-direction: column;
  }
  
  .card img {
    border-radius: 10px;
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .card h2 {
    color: #ff6600;
    margin: 10px 0;
  }
  
  .card .details {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    flex-wrap: wrap;
    flex: 1;
  }
  
  .card .details div {
    flex: 1 1 45%;
    text-align: left;
  }
  
  .card button {
    background-color: #ff6600;
    color: #ffffff;
    size: 15px;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin-top: auto;
    cursor: pointer;
    align-self: center;
  }
  