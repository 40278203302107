
  header {
    margin-bottom: 40px;
  }
  
  .main-image {
    max-width: 100%;
    height: 100%;
    margin-bottom: 20px;
  }
  
  .gallery {
    margin-bottom: 40px;
  }
  
  .gallery-images {
    display: flex;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
  }
  
  .gallery-images img {
    max-width: 150px;
    height: auto;
    border: 2px solid #ddd;
    border-radius: 5px;
    padding: 5px;
    transition: transform 0.2s;
  }
  
  .gallery-images img:hover {
    transform: scale(1.1);
  }
  
  .videos {
    margin-bottom: 40px;
  }
  
  .video-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  .video-container iframe {
    width: 360px;
    height: 215px;
    max-width: 100%;
  }
  
  footer {
    padding: 20px 0;
    background-color: #282c34;
    color: #61dafb;
  }
  
  @media (max-width: 768px) {
    .gallery-images img {
      max-width: 120px;
    }
  
    .video-container iframe {
      width: 300px;
      height: 170px;
    }
  }
  
  @media (max-width: 480px) {
    .gallery-images img {
      max-width: 100px;
    }
  
    .video-container iframe {
      width: 100%;
      height: auto;
    }
  }
  .wellcome-heading{
    color: #183774;
    font-family: Arial, Helvetica, sans-serif;
  }
.para-1{
    font-size: 18px;
    font-family: sans-serif;
    line-height: 30px;
    letter-spacing: 1px;
    
}

/* styles.css */

.banner-image {
  width: 100%;
  height: auto;
  overflow: hidden;
}

.banner-image img {
  width: 100%;
  height: auto;
  display: block;
}

/* Media queries for different screen sizes */

/* Large devices like laptops/desktops */
.banner {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.banner-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}



