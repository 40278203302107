.modal {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: #fefefe;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    width: 90%;
    max-width: 500px;
  }
  
  .close-button {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .payment-section {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .qr-code {
    max-width: 150px;
  }
  
  .bank-details {
    text-align: left;
  }
  
  .adopt-form {
    display: flex;
    flex-direction: column;
  }
  
  .adopt-form label {
    margin-bottom: 10px;
  }
  
  .adopt-form input {
    padding: 8px;
    margin-top: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .adopt-form button {
    padding: 10px;
    background-color: #026817;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .adopt-form button:hover {
    background-color: #024f12;
  }
  